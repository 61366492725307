import React from 'react';

const EmailTemplateSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512',
  className
}: {
  height?: any;
  width?: any;
  viewBox?: any;
  className?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={className}
        viewBox={viewBox}
        fill="currentColor"
      >
        <g>
          <g>
            <path
              d="M302.545,69.818h-93.091c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h93.091
			c6.982,0,11.636-4.655,11.636-11.636S309.527,69.818,302.545,69.818z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M256,128H151.273c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636H256
			c6.982,0,11.636-4.655,11.636-11.636S262.982,128,256,128z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M279.273,221.091h-128c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h128
			c6.982,0,11.636-4.655,11.636-11.636S286.255,221.091,279.273,221.091z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M197.818,174.545h-46.545c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h46.545
			c6.982,0,11.636-4.655,11.636-11.636S204.8,174.545,197.818,174.545z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M290.909,174.545h-46.545c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h46.545
			c6.982,0,11.636-4.655,11.636-11.636S297.891,174.545,290.909,174.545z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M360.727,174.545h-23.273c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h23.273
			c6.982,0,11.636-4.655,11.636-11.636S367.709,174.545,360.727,174.545z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M360.727,128h-58.182c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h58.182
			c6.982,0,11.636-4.655,11.636-11.636S367.709,128,360.727,128z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M484.073,151.273l-24.436-15.127c-5.818-3.491-12.8-1.164-16.291,3.491c-3.491,5.818-1.164,12.8,3.491,16.291
			l24.436,15.127c10.473,6.982,16.291,17.455,16.291,30.255v275.782c0,6.982-4.655,11.636-11.636,11.636H34.909
			c-6.982,0-11.636-4.655-11.636-11.636V200.145c0-11.636,5.818-23.273,16.291-30.255L64,154.764
			c5.818-2.327,6.982-9.309,4.655-15.127s-10.473-6.982-16.291-3.491l-24.436,15.127C10.473,161.745,0,180.364,0,200.145v276.945
			C0,496.873,15.127,512,34.909,512h442.182C496.873,512,512,496.873,512,477.091V200.145
			C512,180.364,501.527,161.745,484.073,151.273z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M146.618,339.782c-4.655-3.491-12.8-2.327-16.291,2.327L48.873,446.836c-3.491,4.655-3.491,12.8,2.327,16.291
			c2.327,1.164,4.655,2.327,6.982,2.327c3.491,0,6.982-1.164,9.309-4.655l81.455-104.727
			C152.436,351.418,152.436,343.273,146.618,339.782z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M463.127,446.836l-81.455-104.727c-4.654-4.655-11.636-5.818-16.291-2.327c-4.655,3.491-5.818,11.636-2.327,16.291
			L444.509,460.8c2.327,3.491,5.818,4.655,9.309,4.655c2.327,0,4.655-1.164,6.982-2.327
			C465.455,459.636,466.618,451.491,463.127,446.836z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M463.127,214.109c-4.655-4.655-11.636-5.818-16.291-1.164L278.109,350.255c-12.8,10.473-31.418,10.473-44.218,0
			L65.164,211.782c-4.655-4.655-12.8-3.491-16.291,1.164c-4.655,4.655-3.491,12.8,1.164,16.291l168.727,138.473
			c10.473,9.309,23.273,12.8,37.236,12.8s26.764-4.655,37.236-12.8l168.727-138.473
			C466.618,225.745,466.618,218.764,463.127,214.109z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M384,0H128c-19.782,0-34.909,15.127-34.909,34.909v174.545c0,6.982,4.655,11.636,11.636,11.636
			s11.636-4.655,11.636-11.636V34.909c0-6.982,4.655-11.636,11.636-11.636h256c6.982,0,11.636,4.655,11.636,11.636v174.545
			c0,6.982,4.655,11.636,11.636,11.636c6.982,0,11.636-4.655,11.636-11.636V34.909C418.909,15.127,403.782,0,384,0z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};
export default EmailTemplateSvg;
