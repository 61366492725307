import store from 'store';
import APIService from 'services/api.service';
import api from 'config/api';
import { successMessage, errorMessage } from 'helpers/ToastNotificationHelper';
import {
  getUserInfo,
  storeUserInfo,
  removeLocalStorage
} from 'helpers/StorageHelper';
import {
  getBranchObj,
  getCountryObj,
  getCurrencyObj,
  getDepartmentObj,
  getEmployeeTypeObj,
  getIndustryObj,
  getRoleObj,
  getShiftObj,
  getSkillObj,
  getTimezoneObj,
  getJobTitleObj,
  getLeaveTypeObj,
  getPayTypeObj,
  getEmployeeObj,
  getCandidateStatusObj,
  getInterviewLevelObj,
  getCandidateObj,
  getClientObj,
  getModuleObj,
  getVisaTypeObj,
  getJobOpeningStatusObj,
  getJobOpeningObj,
  getInterviewStatusObj,
  getAttachmentCategoryObj,
  getLeaveStatusObj,
  getReferenceObj,
  getHolidayObj,
  getServiceCategoryObj,
  getCompanyServiceObj,
  getAccountTypeObj,
  getLeadStageObj,
  getLeadAccountObj,
  getCalendarStatusObj,
  getCalendarLabelObj,
  getEmailSignatureObj,
  getEmailTemplateObj
} from 'helpers/DataHelper';
import sourceboosterApiService from 'services/sourcebooster.api.service';
import { getConfiguredLinkAccountSettingList } from './LinkAccountSettingAction';
import { t } from 'i18next';
export const manageAPICall = async (
  apiurl: string,
  method: string,
  data: object,
  callback?: any
) => {
  let response;
  if (method == 'GET') {
    response = await APIService.getData(apiurl, data);
  } else if (method == 'POST') {
    response = await APIService.submitData(apiurl, data);
  } else if (method == 'PATCH') {
    response = await APIService.submitPatchData(apiurl, data);
  } else if (method == 'DELETE') {
    response = await APIService.deleteData(apiurl, data);
  } else if (method == 'POSTFORMDATA') {
    response = await APIService.submitFormData(apiurl, data);
  }
  if (response) {
    if (callback) {
      callback(response.status, response);
    } else {
      return response;
    }
  }
};
export const manageSourceBoosterAPICall = async (
  apiurl: string,
  method: string,
  data: object,
  token: string,
  callback?: any
) => {
  let response;
  if (method == 'POST') {
    response = await sourceboosterApiService.submitData(apiurl, data, token);
  } else if (method == 'GET') {
    response = await sourceboosterApiService.getData(apiurl, data, token);
  }
  if (response) {
    if (callback) {
      callback(response.status, response);
    } else {
      return response;
    }
  }
};
export const getDropdownListAPI = async (
  apiurl: string,
  apiMethod: string,
  payload: any,
  callback: any
) => {
  const response = await manageAPICall(apiurl, apiMethod, payload);
  if (response && response.status) {
    const responseData = response.data.data;
    callback(responseData);
  }
};
export const getFormFieldsAPI = async (payload: any, callback: any) => {
  const response = await manageAPICall(
    api.formFieldList.url,
    api.formFieldList.method,
    payload
  );
  callback(response.status, response);
};
export const getListFieldsAPI = async (payload: any, callback: any) => {
  payload.sort = {
    list_sort_order: 1
  };
  const response = await manageAPICall(
    api.listFieldList.url,
    api.listFieldList.method,
    payload
  );
  callback(response.status, response);
};
export const timezoneList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.timezoneList.url,
    api.timezoneList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const timezoneOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    timezoneList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getTimezoneObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// provider
export const linkAccountOptions = (
  inputValue: string,
  setDisabled = false,
  showOtherOption = true
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    getConfiguredLinkAccountSettingList(
      payload,
      (status: boolean, responseData: any) => {
        const listData: any = [];
        if (status) {
          responseData.data.forEach(function (element: any) {
            // listData.push(getTimezoneObj(element, setDisabled));
            listData.push({
              name: t(element.type),
              slug: element.type
            });
          });
        }
        if (showOtherOption) {
          listData.push({
            name: t('other'),
            slug: 'other'
          });
        }
        resolve(listData);
      }
    );
  });
// INDUSTRY
export const industryList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.industryList.url,
    api.industryList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const industryOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    industryList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getIndustryObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
export const userList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.userList.url,
    api.userList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const countryList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.countryList.url,
    api.countryList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const countryOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      name: inputValue,
      limit: 10
    };
    countryList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getCountryObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
export const stateList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.stateList.url,
    api.stateList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const cityList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.cityList.url,
    api.cityList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const currencyList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.currencyList.url,
    api.currencyList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const currencyOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        code: inputValue
      },
      limit: 10
    };
    currencyList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getCurrencyObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// role
export const roleList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.roleList.url,
    api.roleList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const roleOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    roleList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getRoleObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// skill
export const skillList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.skillList.url,
    api.skillList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const skillOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    skillList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getSkillObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// shift
export const shiftList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.shiftList.url,
    api.shiftList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const shiftOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    shiftList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getShiftObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// branch.........
export const branchList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.branchList.url,
    api.branchList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const branchOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    branchList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getBranchObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// module.............
export const moduleList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.moduleList.url,
    api.moduleList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const moduleOptions = (inputValue: string) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    moduleList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.forEach(function (element: any) {
        listData.push(getModuleObj(element));
      });
      resolve(listData);
    });
  });
// department
export const departmentList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.departmentList.url,
    api.departmentList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const departmentOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    departmentList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getDepartmentObj(element, setDisabled));
      });
      resolve(listData);
    });
  });

// employee type
export const employeeTypeList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.employeeTypeList.url,
    api.employeeTypeList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const employeeTypeOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    employeeTypeList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getEmployeeTypeObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// job title.........
export const jobTitleList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.jobTitleList.url,
    api.jobTitleList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const jobTitleOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    jobTitleList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getJobTitleObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// attachment category
export const attachmentCategoryList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.jobOpeningAttachmentCategoryList.url,
    api.jobOpeningAttachmentCategoryList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const attachmentCategoryOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    attachmentCategoryList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getAttachmentCategoryObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// holiday
export const holidayList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.holidayList.url,
    api.holidayList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const holidayOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        title: inputValue
      },
      limit: 10
    };
    holidayList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getHolidayObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// job opening.........
export const jobOpeningList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.jobOpeningList.url,
    api.jobOpeningList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const jobOpeningOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        job_title: inputValue
      },
      limit: 10
    };
    jobOpeningList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getJobOpeningObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// jobOpeningStatusList
export const jobOpeningStatusList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.jobOpeningStatusList.url,
    api.jobOpeningStatusList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const jobOpeningStatusOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    jobOpeningStatusList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getJobOpeningStatusObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// pay type.........
export const payTypeList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.payTypeList.url,
    api.payTypeList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const payTypeOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    payTypeList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getPayTypeObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// employee allowed leave type.........
export const employeeLeaveTypeList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.employeeAllowedLeaveTypeList.url,
    api.employeeAllowedLeaveTypeList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const employeeLeaveTypeOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    employeeLeaveTypeList(
      { filters: { name: inputValue } },
      (responseData: any) => {
        const listData: any = [];
        responseData.data.forEach(function (element: any) {
          listData.push(getLeaveTypeObj(element, setDisabled));
        });
        resolve(listData);
      }
    );
  });
// leave type.........
export const leaveTypeList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.leaveTypeList.url,
    api.leaveTypeList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const leaveTypeOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    leaveTypeList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getLeaveTypeObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// leave status.........
export const leaveStatusList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.leaveStatusList.url,
    api.leaveStatusList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const leaveStatusOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    leaveStatusList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getLeaveStatusObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// active employee list...................
export const allActiveEmployeeList = async (callback: any) => {
  const payload = {
    filters: {
      status: true
    },
    page: 1,
    limit: 3000
  };
  const response = await manageAPICall(
    api.employeeList.url,
    api.employeeList.method,
    payload
  );
  if (response && response.status) {
    callback(response.status, response);
  }
};
// employee list...............
export const employeeList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.employeeList.url,
    api.employeeList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const employeeOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    employeeList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getEmployeeObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// interview status
export const interviewStatusList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.interviewStatusList.url,
    api.interviewStatusList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const interviewStatusOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    interviewStatusList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getInterviewStatusObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
export const parentInterviewStatusOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue,
        parent: null
      },
      name: inputValue,
      limit: 10
    };
    interviewStatusList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getInterviewStatusObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// candidate status
export const candidateStatusList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.candidateStatusList.url,
    api.candidateStatusList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const candidateStatusOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    candidateStatusList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getCandidateStatusObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// client .......
export const clientList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.clientList.url,
    api.clientList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const clientOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    clientList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getClientObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// submitted candidate List.........................
export const submittedCandidateList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.submitCandidateList.url,
    api.submitCandidateList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
// export const submittedCandidateOptions = (
//   inputValue: string,
//   setDisabled = false
// ) =>
//   new Promise(resolve => {
//     const payload = {
//       filters: {
//         name: inputValue
//       },
//       limit: 10
//     };
//     submittedCandidateList(payload, (responseData: any) => {
//       const listData: any = [];
//       responseData.data.forEach(function (element: any) {
//         listData.push(getCandidateObj(element, setDisabled));
//       });
//       resolve(listData);
//     });
//   });
// candidate list.........
export const candidateList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.candidateList.url,
    api.candidateList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const candidateOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    candidateList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getCandidateObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
export const activeCandidateOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue,
        candidate_status_slug: ['active', 'new']
      },
      limit: 10
    };
    candidateList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getCandidateObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// interview Level.........
export const interviewLevelList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.interviewLevelList.url,
    api.interviewLevelList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const interviewLevelOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      sort: { order_no: 1 },
      limit: 10
    };
    interviewLevelList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getInterviewLevelObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// Visa Type options
export const visaTypeList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.visaTypeList.url,
    api.visaTypeList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const visaTypeOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    visaTypeList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getVisaTypeObj(element, setDisabled));
      });
      resolve(listData);
    });
  });

// Reference options
export const referenceList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.referenceList.url,
    api.referenceList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const referenceOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      limit: 10
    };
    referenceList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getReferenceObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
export const mobileCodeList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.mobileCodeList.url,
    api.mobileCodeList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
// crm...................
// Service Category
export const serviceCategoryList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.serviceCategoryList.url,
    api.serviceCategoryList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const serviceCategoryOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    serviceCategoryList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getServiceCategoryObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// service
export const companyServiceList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.companyServiceList.url,
    api.companyServiceList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const companyServiceOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    companyServiceList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getCompanyServiceObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// account type
export const accountTypeList = async (params: any, callback: any) => {
  const payload = params;
  let responseData = [];
  const response = await manageAPICall(
    api.accountTypeList.url,
    api.accountTypeList.method,
    payload
  );
  if (response && response.status) {
    responseData = response.data.data;
    callback(response.data);
  }
};
export const accountTypeOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    accountTypeList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getAccountTypeObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// Lead Stage type
export const leadStageList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.leadStageList.url,
    api.leadStageList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const leadStageOptions = (
  inputValue: string,
  paramObj: any,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue,
        ...(paramObj || {})
      },
      limit: 10
    };
    leadStageList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.forEach(function (element: any) {
        listData.push(getLeadStageObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// Lead
export const leadList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.leadList.url,
    api.leadList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
// Lead Account
export const leadAccountList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.accountList.url,
    api.accountList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const leadAccountOptions = (inputValue: string, setDisabled = false) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    leadAccountList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getLeadAccountObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// calendar status
export const calenderTaskStatusList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.calenderTaskStatusList.url,
    api.calenderTaskStatusList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const calendarTaskStatusOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    calenderTaskStatusList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.forEach(function (element: any) {
        listData.push(getCalendarStatusObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// calendar label
export const calenderTaskLabelList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.calenderTaskLabelList.url,
    api.calenderTaskLabelList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const calendarTaskLabelOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    calenderTaskLabelList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.forEach(function (element: any) {
        listData.push(getCalendarLabelObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// Email Signature
export const emailSignatureList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.emailSignatureList.url,
    api.emailSignatureList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const emailSignatureOptions = (
  inputValue: string,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue
      },
      name: inputValue,
      limit: 10
    };
    emailSignatureList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getEmailSignatureObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// Email Template
export const emailTemplateList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.emailTemplateList.url,
    api.emailTemplateList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const emailTemplateOptions = (
  inputValue: string,
  additionalParams: any,
  setDisabled = false
) =>
  new Promise(resolve => {
    const payload = {
      filters: {
        name: inputValue,
        ...additionalParams
      },
      name: inputValue,
      limit: 10
    };
    emailTemplateList(payload, (responseData: any) => {
      const listData: any = [];
      responseData.data.forEach(function (element: any) {
        listData.push(getEmailTemplateObj(element, setDisabled));
      });
      resolve(listData);
    });
  });
// Email Modules
export const emailModuleList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.emailModuleList.url,
    api.emailModuleList.method,
    payload
  );
  if (response && response.status) {
    const listModuleData: any = [];
    response.data.modules.forEach(function (element: any) {
      listModuleData.push({
        slug: element,
        name: t(element)
      });
    });
    //.........
    const listSystemModuleData: any = response.data.system_modules;
    //..............
    const listFieldModuleData: any = [];
    response.data.copy_variable.forEach(function (element: any) {
      listFieldModuleData.push({
        slug: element,
        name: t(element)
      });
    });
    callback(response.status, {
      listModuleData: listModuleData,
      listSystemModuleData: listSystemModuleData,
      listFieldModuleData: listFieldModuleData,
      message: response.message
    });
  }
};
// dynamic field for email template
export const emailFieldList = async (params: any, callback: any) => {
  const payload = params;
  const response = await manageAPICall(
    api.emailFieldList.url,
    api.emailFieldList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
